.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  color: #EC4B43 !important;
}

.link:visited {
  color: #666;
}

.link:link {
  color: #666;
}
.top-nav .l{
  /* text-align: right; */
  float: right;
}
.l {
  margin-left: 12px;
}

.red {
  color: #EC4B43;
}