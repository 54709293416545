.active {
  color: #fff;
  background-color: #EC4B43;
}

.search {
  padding: 0 24px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
}

.search-box {
  border: 2px solid #EC4B43;
  overflow: hidden;
  display: inline;
}

.search-box input {
  display: inline-block;
  height: 36px;
  width: 412px;
  line-height: 36px;
  text-indent: 45px;
  background: #fff url(../../assets/images/icon1.png) no-repeat 16px -526px;
  outline: 0;
  font-size: 14px;
  border: 0;
  box-sizing: border-box;
  cursor: text;
  text-shadow: none;
  color: initial;
  word-spacing: normal;
}

.search-box button {
  width: 100%;
  height: 36px;
  font-size: 16px;
  color: #fff;
  background-color: #ec4b43;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  white-space: pre;
  text-align: center;
  padding: 0;
  border: 0;
  margin: 0;
}