.icon-0 {
  background: url(../../assets/images/n_icon.png) no-repeat 0 -474px;
}

.icon-1 {
  background: url(../../assets/images/n_icon.png) no-repeat 0 -474px;
  background-position: 0 -560px;
}

.icon-2 {
  background: url(../../assets/images/n_icon.png) no-repeat 0 -474px;
  background-position: 0 -520px;
}

.icon-3 {
  background: url(../../assets/images/n_icon.png) no-repeat 0 -474px;
  background-position: 0 -604px;
}

.icon-4 {
  background: url(../../assets/images/n_icon.png) no-repeat 0 -474px;
  background-position: 0 -646px;
}