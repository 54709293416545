.detail-title {
  font-weight: bold;
  margin-top: 30px !important;
}

.margin {
  margin-top: 30px !important;
}

.bold {
  font-weight: bold !important;
}

.indent {
  text-indent: 20px;
}
